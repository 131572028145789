
import axios from 'axios'
import portList from './port.js'
import { Toast } from "vant";
import Qs from 'qs'
let baseURL = 'https://app.wuhuatian.com:9999'
// let baseURL = 'http://192.168.110.222:9999'
const AJAX = axios.create({
  baseURL: baseURL,
  timeout: 60000, // 请求超时时间
  // responseType: 'json',
  dataType: 'json',
  withCredentials: true, // 是否允许带cookie这些
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
});
// 对所有的请求进行拦截
AJAX.interceptors.request.use(config => {
  if (config.method === 'post' || config.method === 'put' || config.method === 'delete' || config.method === 'get') {
    // 序列化、
    if (Object.prototype.toString.call(config.data) === '[object Object]') {
      config.data = JSON.stringify(config.data)
    }
  }
  if (localStorage.getItem('key')) {
    config.headers['token'] = localStorage.getItem('key')
  }
  return config
})

// response interceptor（接收拦截器）
// 使用axios的响应拦截器  
AJAX.interceptors.response.use(
  // 成功的响应  
  (response) => {
    // 从响应中提取数据  
    const res = response.data;

    // 检查返回的数据中的错误码  
    if (res.code && res.code != '200') {
      // 如果有错误，使用Toast组件显示错误信息  
      Toast({
        message: res.msg,  // 错误信息  
        position: "bottom"  // Toast组件的位置  
      });

      // 如果错误码是200，显示特殊的错误信息  
      if (res.code == '200') {
        Toast({
          message: '服务繁忙，如遇刷新后仍报错请咨询在线客服',  // 特殊的错误信息  
          position: "bottom"  // Toast组件的位置  
        });
      }
    }

    // 返回响应数据  
    return res;
  },

  // 错误的响应  
  (error) => {
    // 在控制台打印错误信息  
    console.log(error);
  }
);


var API = {}
var codeShow = ''

// 定义函数 run，接受两个参数 p 和 note  
function run(p, note) {
  // 将 note 赋值给全局变量 codeShow  
  codeShow = note

  // 判断 note 是否为函数类型，如果是，则直接返回 note  
  if (typeof note === 'function') {
    return note;
  }

  // 初始化 url 为空字符串，用于后续组装请求地址  
  // 组装地址  
  var url = '';

  // 将 note.type 转为小写，并赋值给 type，如果 note.type 不存在或为空，则 type 默认为 'get'  
  var type = (note.type || 'get').toLocaleLowerCase();

  // 判断 note.url 是否为函数，如果是，则执行该函数并将结果赋值给 url，否则直接将 note.url 赋值给 url  
  url += (typeof note.url === 'function') ? note.url() : note.url

  // 返回一个函数，该函数接受两个参数 da 和 fn  
  return function (da, fn) {
    // 如果 da 等于 'url'，则直接返回组装好的 url  
    if (da === 'url') {
      return url
    }

    // 初始化 ContentType 为空字符串，用于后续设置请求头中的 'Content-Type' 字段  
    let ContentType = '';

    // 如果 note.ContentType 存在，则将其值赋值给 ContentType  
    if (note.ContentType) {
      ContentType = note.ContentType;
    }

    // 初始化 re 为 AJAX 对象，用于后续发送 HTTP 请求  
    let re = AJAX;

    // 判断 type 是否为 'post'，如果是，则执行以下操作：  
    // - 初始化一个 Promise 对象 re，该对象在请求成功时 resolve，失败时 reject  
    // - 将 da 赋值给请求的数据，如果 da 不存在或为空，则默认为空对象 {}  
    // - 使用 AJAX.post 发送 POST 请求，设置请求头 'Content-Type' 字段，请求成功后，如果响应结果为空，则返回 false；否则，将响应结果通过 note.filter 函数过滤后 resolve。请求失败时 reject。  
    if (type === 'post') {
      re = new Promise((resolve, reject) => {
        da = da || {};
        AJAX.post(url, da, { headers: { 'Content-Type': ContentType || 'application/json;charset=UTF-8' } }).then(res => {
          if (!res) {
            return false
          }
          resolve(typeof note.filter === 'function' ? note.filter(res) : res)
        }, err => {
          reject(err)
        })
      })
    }

    // 判断 type 是否为 'get'，如果是，则执行以下操作：  
    // - 初始化一个 Promise 对象 re，该对象在请求成功时 resolve，失败时 reject  
    // - 使用 AJAX.get 发送 GET 请求，设置请求参数为 da。请求成功后直接 resolve。请求失败时 reject。  
    if (type === 'get') {
      re = new Promise((resolve, reject) => {
        AJAX.get(url, { params: da }).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      })
    }

    // 判断 type 是否为 'put'，如果是，则执行以下操作：  
    // - 初始化一个 Promise 对象 re，该对象在请求成功时 resolve，失败时 reject  
    // - 使用 AJAX.put 发送 PUT 请求，设置请求数据为 da。请求成功后，将响应结果通过 note.filter 函数过滤后 resolve。请求失败时 reject。  
    if (type === 'put') {
      re = new Promise((resolve, reject) => {
        AJAX.put(url, da).then(res => {
          resolve(typeof note.filter === 'function' ? note.filter(res) : res)
        }, err => {
          reject(err)
        })
      })
    }

    // 返回 re 对象，用于后续执行 HTTP 请求操作（GET、POST 或 PUT）  
    return re;
  }
}

// 对于portList.list中的每一个属性p  
for (const p in portList.list) {
  // 在API对象中创建一个新的空对象，名为p  
  API[p] = {}

  // 对于portList.list[p]中的每一个属性l  
  for (const l in portList.list[p]) {
    // 运行函数run，参数为p和portList.list[p][l]，将结果赋值给API[p][l]  
    API[p][l] = run(p, portList.list[p][l])
  }
}
// 在API模块中定义一个名为init的函数，该函数接收两个参数，yes和no  
API.init = function (yes, no) {
  // 调用API模块中user对象的status方法，返回一个Promise对象usr  
  const usr = API.user.status()

  // 使用then方法处理Promise对象usr的成功返回值  
  usr.then(res => {
    // 如果返回的状态码为200，表示请求成功  
    if (res.status === '200') {
      // 调用传入的yes函数  
      yes()
    } else {
      // 如果请求失败，根据no参数的类型进行处理  
      if (typeof no === 'string') {
        // 如果no是字符串类型，将其赋值给window.location.href，页面将跳转到no指定的URL  
        window.location.href = no
        return
      }
      if (typeof no === 'function') {
        // 如果no是函数类型，调用no函数  
        no()
      }
    }
    // 使用catch方法处理Promise对象usr的失败返回值  
  }).catch(err => {
    // 在控制台打印错误信息err  
    console.log(err)
  })
}
/* ---------------------初始化默认参数-----------------------*/

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)

export default {
  install: function (Vue, Option) {
    Object.defineProperty(Vue.prototype, 'API', { value: API })
  },
  init: API.init,
  // baseURL: baseURL
}
export { baseURL, axios }