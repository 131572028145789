import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import home from "@/views/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    children:[
      {
        path: 'machinery',
        name: 'machinery',
        component: () => import('../views/common/machinery.vue'),
        meta: { showNav: true }
      },
      {
        path: 'agricultural',
        name: 'agricultural',
        component: () => import('../views/common/agricultural.vue'),
        meta: { showNav: true }
      },
      {
        path: 'farming',
        name: 'farming',
        component: () => import('../views/common/farming.vue'),
        meta: { showNav: true }
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('../views/common/news.vue'),
        meta: { showNav: true }
      },
      {
        path: 'knowledge',
        name: 'knowledge',
        component: () => import('../views/common/knowledge.vue'),
        meta: { showNav: true }
      },
      
    ]
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info/info.vue'),
    children:[
      {
        path: 'infodetail',
        name: 'infodetail',
        component: () => import('../views/info/infodetail.vue'),
        meta: { showNav: true }
      },
      {
        path: 'infodetailcompany',
        name: 'infodetailcompany',
        component: () => import('../views/info/infodetailcompany.vue'),
        meta: { showNav: true }
      },
      
    ]
  },
  {
    path: '/about',
    name: 'about',
    component:  () => import('../views/about/about.vue')
  },
  {
    path: '/company',
    name: 'company',
    component:  () => import('../views/company/company.vue')
  },
  {
    path: '/product',
    name: 'product',
    component:  () => import('../views/product/product.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 记录当前加载的子页面  
  // store.commit('setQuestionnairesView', /agricultural/.test(to.path))
  // store.commit('setQuestionnairesView', /farming/.test(to.path))
  // store.commit('setQuestionnairesView', /machinery/.test(to.path))
  // store.commit('setQuestionnairesView', /info/.test(to.path))
  // store.commit('setQuestionnairesView', /news/.test(to.path))
  next();
});

export default router
