import axios from 'axios'
import { headers, baseUrl as baseURL, timeout, transformResponse } from './axios.config'

const request = axios.create({
  headers,
  baseURL,
  timeout,
  transformResponse
})
request.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

export default request