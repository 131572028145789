<template>
  <div class="footer-box">
    <div class="footer-cont">
      <div class="logo-bx">
        <div class="logo-l">
          <img class="logos" src="../assets/image/footer/foot-logo.png" alt="">
          <!-- <div class="f1">敬畏自然·爱护耕田</div> -->
          <img class="f1" src="../assets/image/f1.png" alt="">
        </div>
       
        <div class="mobile">
          <div> <span>0530-6788878</span> 13681157961</div>
          <div style="text-align: right;">wuhuatianjs@126.com</div>
        </div>
      </div>
      <div class="foot-bot">
        <div class="bot-l">
          <div class="font">
            也许你还想了解！
          </div>
          <div class="wx-bx">
            <div class="wx-item">
              <img src="../assets/image/footer/foot2.png" alt="">
              <div>
                关注公众号
              </div>
            </div>
            <div class="wx-item">
              <img src="../assets/image/footer/foot1.png" alt="">
              <div>
                官方微信
              </div>
            </div>
          </div>
          <div class="font2">
            Copyright©2024 www.wuhuatian.com
          </div>
          <div class="font3">
          
          <a href="http://beian.miit.gov.cn" target="_blank"> 京ICP备2023021809号-1</a>
        </div>
        </div>
        <div class="bot-r">
          <div class="items color1"  style="margin-bottom: 40px;">
            <div>关于五画田</div>
            <div>产品中心</div>
            <div>新闻资讯</div>
            <div>联系我们</div>
          </div>
          <div class="items color">
            <div @click="toCompany(1)">企业介绍</div>
            <div @click="toProduct(1)">五画田APP</div>
            <div @click="toInfo(1)">公司动态</div>
            <div @click="toAbout(1)">联系我们</div>
          </div>
          <div class="items color">
            <div @click="toCompany(2)">企业文化</div>
            <div @click="toProduct(2)">智能AI田小宝</div>
            <div @click="toInfo(0)">农业资讯</div>
            <div></div>
          </div>
          <div class="items color">
            <div @click="toCompany(3)">发展历程</div>
            <div @click="toProduct(3)">统筹平台</div>
            <div></div>
            <div></div>
          </div>
          <div class="items color">
            <div @click="toCompany(4)">权威证书</div>
            <div @click="toProduct(4)">农机管理平台</div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="bot-r1">
          <div class="items"  style="margin-bottom: 10px;">
            <div class="fonts">关于五画田</div>
            <div @click="toCompany(1)">企业介绍</div>
            <div @click="toCompany(2)">企业文化</div>
            <div @click="toCompany(3)">发展历程</div>
            <div @click="toCompany(4)">权威证书</div>
          </div>
          <div class="items color">
            <div class="fonts">产品中心</div>
            <div @click="toProduct(1)">五画田APP</div>
            <div @click="toProduct(2)">智能AI田小宝</div>
            <div @click="toProduct(3)">统筹平台</div>
            <div @click="toProduct(4)">农机管理平台</div>
          </div>
          <div class="items color">
            <div class="fonts">新闻资讯</div>
            <div @click="toInfo(1)">公司动态</div>
            <div @click="toInfo(0)">农业资讯</div>
            <div></div>
            <div></div>
          </div>
          <div class="items color">
            <div class="fonts">联系我们</div>
            <div @click="toAbout(1)">联系我们</div>
            <div></div>
            <div></div>
            <div></div>
          </div>
         
        </div>
        <div class="bot">
          Copyright©2024 www.wuhuatian.com
        </div>
        <div class="bot" style="padding-top: .1rem;">
          京ICP备2023021809号-1
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'footerCommon',
  data() {
    return {
      arr: [
       
      ]
    }
  },
  methods: {
    toAbout(index) {
      this.setNavIndex('/about',index)
    },
    toInfo(index) {
      this.setNavIndex('/info',index)
    },
    toProduct(index) {
      this.setNavIndex('/product',index)
    },
    toCompany(index) {
      this.setNavIndex('/company',index)
    },
    setNavIndex(router,index) {
      sessionStorage.setItem('select', router)
      this.$store.state.headerContent = sessionStorage.getItem('select') || '/'
      if (this.$route.path !== router) this.$router.push(router)
      this.$router.push({
        path: router+'?type='+index
      })
    },
   
  }
}
</script>
<style lang="less" scoped>
// pC端
@media screen and (min-width: 980px) {
  .footer-box{
    height: 484px;
    background-color: #008C11;
    .footer-cont{
      box-sizing: border-box;
      padding: 40px 16% 5px 16%;
      color: #fff;
      .logo-bx{
        display: flex;
        justify-content: space-between;
        // img{
        //   width: 400px;
        //   height: 120px;
        // }
        .mobile{

          div{
            font-weight: 500;
            font-family: Source Han Sans CN;
            &:nth-child(1){
              font-size: 32px;
              
            }
            &:nth-child(2){
              font-size: 20px;
              
            }
          }
        }
      }
      .foot-bot{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .font{
            font-size: 32px;
            font-weight: 500;
            font-family: Source Han Sans CN;
        }
        .wx-bx{
          display: flex;
          margin-top: 18px;
          .wx-item{
            text-align: center;
            margin-right: 24px;
            img{
              width: 104px;
              height: 104px;
            }
          }
        }
        .font2{
          font-size: 20px;
          font-weight: 500;
          margin-top: 25px;
          font-family: Source Han Sans CN;
        }
        .font3{
          font-weight: 500;
          font-size: 18px;
          margin-top: 10px;
        }
        .bot-r{
          .items{
            font-size: 16px;
            display: flex;
            margin-bottom: 20px;
            div{
              width: 96px;
              margin-right: 89px;
              &:last-child{
                margin-right: 0;
              }
            }
          } 
          
        }
      }
    }
  }
  // .f1{
  //   color: #FFFFFF;
  //   font-size: 24px;
  // }
  .bot{
    display: none;
  }
  .bot-r1{
      display: none;
    }
    .color1{
         font-weight: 600; 
        }
    .logo-l{
      img{
        display: block;
      }
      .f1{
        width: 206px;
        height: 24px;
        margin-top: 20px;
      }
      .logos{
        width: 206px;
        height: 60px;
      }
    }
}



// 移动端
@media screen and (max-width: 980px) {
  
  .footer-box{
    height: 9.6rem;
    background: #008C11;
    box-sizing: border-box;
    
    .footer-cont{
      padding: .5rem .6rem  .22rem .6rem;
    }
    .font3{
      display: none;
    }
    .logo-bx{
      display: flex;
      justify-content: space-between;
      color: #fff;
      img{
        width: 1.83rem;
        height: .58rem;
      }
      .f1{
        // display: none;
        width: 2.06rem;
        height: .26rem;
        margin-top: .1rem;
      }
      .logo-l{
        width: 2.1rem;
        font-size: .24rem;
        div{
          padding-top: .12rem;
        }
      }
      .mobile{
        padding-top: .2rem;
        text-align: right;
        div{
          &:nth-child(1){
            font-size: .32rem;
            font-weight: 600;
          }
          &:nth-child(2){
            font-size: .22rem;
          }
        }
      }
    }
    .font2{
      display: none;
    }
    .foot-bot{
      .font{
        font-size: 32px;
        font-weight: 600;
        padding-top: .5rem;
        color: #fff;
      }
      .wx-bx{
        display: flex;
        
        .wx-item{
          padding-top: .28rem;
          text-align: center;
          width: 1.44rem;
            height: 1.44rem;
            margin-right: .56rem;
          img{
            width: 1.44rem;
            height: 1.44rem;
           
          }
          div{
            color: #fff;
            font-weight: 600;
            font-size: .24rem;

          }
        }

      }
    }
    .bot-l{
      .font{
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: .32rem;
      }
    }
    .bot-r{
      display: none;
    }
    .bot-r1{
      display: flex;
      padding-top: 1rem;
      color: #fff;
      
      .items{
        font-size: .24rem;
        // margin-bottom: .24rem;
        width: 1.7rem;
        margin-right: .17rem;

        &:last-child{
          margin-right: 0;
        }
        div{
          padding-bottom: .24rem;
        }
        
      }
    }

  }
  .fonts{
    font-family: Source Han Sans CN;
    font-weight: 500;
  }

  .bot{
    text-align: center;
    font-size: .24rem;
    color: #fff;
  }
}





@media screen and (min-width: 980px) and (max-width: 1200px) {
  // .footer-cont {
  //   width: 90%;
  //   margin: 0 auto;
  // }

}

@media screen and (min-width: 1200px) {
  // .footer-cont {
  //   width: 70% !important;
  //   margin: 0 auto;
  // }
}

@media screen and (min-width: 980px) and (max-width: 1200px) {}
</style>