
export default {
  gate: '',
  version: 'weixin', // 默认版本
  module: 'filter', // 默认模块
  filter: function (res) {
    console.log(res)
  }, // 默认拦截过滤器
  inter: function (res) {
    console.log(res)
  }, // 默认拦截器
  list: {
    market: {
      guestbook: {
        url: 'jeecg-boot/guestbook/guestbook/add',
        type: 'post'
      },
      getQuestionnaireData: {
        url: 'jeecg-boot/questionnaire/whtwebQuestionnaire/AllData',
        type: 'get'
      },
      newlist:{
        url: 'news_information/newsInformation/getList',
        type: 'get'
      },
      queryById:{
        url:'news_information/newsInformation/queryById',
        type: 'post'
      },
      queryViewVolume:{
        url:'sysuserfootprint/sysUserFootprint/queryViewVolume',
        type: 'get'
      },
      addprint:{
        url:'sysuserfootprint/sysUserFootprint/add',
        type:'post'
      }
    }
  }
}