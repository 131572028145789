<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="homeView">
    <div  v-show="$route.name == 'home'">
      <div class="pc-swiper">
        <swiper :options="swiperOptions" ref="mySwiper">
          <swiper-slide v-for="(item,index) in images" :key="index" style="position: relative;">
            <div class="banner-bx">
              <img class="images" :src="item.img" />
              <img class="wht" src="../assets/image/wht.png" alt="">
              <span class="spans">五画田</span>
              <div class="bot">
                <span>{{ item.title }}</span>
                <span>{{ item.cont }}</span>
              </div>
            </div>
          </swiper-slide>
          <!-- 如果你需要分页器 -->
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          
          <!-- 如果你需要导航按钮 -->
          <div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
          <div class="swiper-button-next" slot="button-next" @click="next"></div>
        </swiper>
      </div>
      <div class="app-swiper">
        <swiper :options="swiperOptions" ref="mySwiper1">
          <swiper-slide v-for="(item,index) in images" :key="index" style="position: relative;">
            <div class="banner-bx" :style="{backgroundImage: 'url(' + item.img1 + ')'}">
          
              <img class="wht" src="../assets/img/wht.png" alt="">
              <div class="spans">五画田</div>
              <div class="bot">
                <div>{{ item.title }}</div>
                <div>{{ item.cont }}</div>
              </div>
            </div>
            
          
          
          </swiper-slide>
          <!-- 如果你需要分页器 -->
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          
          <!-- 如果你需要导航按钮 -->
          <div class="swiper-button-prev" slot="button-prev" @click="prev1"></div>
          <div class="swiper-button-next" slot="button-next" @click="next1"></div>
        </swiper>
      </div>
      <div class="box">
        <div class="t">
          北京五画田技术有限公司
        </div>
        <div class="font-bx">
            <div class="font">
            北京五画田是一家种植服务提供公司

          </div>
          <div class="font">
            集软件产品开发、配电智能灌溉设备生产、农业数字化管理、服务于一体
          </div>
          <div class='font'>
            公司拥有精诚团结的技术团队力量，数字化经营管理，做农业智能管理及服务，提升农业科
          </div>
          <div class="font">
            学管理，促进乡村振兴，让种植户种田更方便，降本增效。
          </div>
        </div> 
        <div class="font-bx1">
            <div class="font">
              北京五画田是一家种植服务提供公司
              集软件产品开发、配电智能灌溉设备生产、农业数字化管理、服务于一体
              公司拥有精诚团结的技术团队力量，数字化经营管理，做农业智能管理及服务，提升农业科学管理，促进乡村振兴，让种植户种田更方便，降本增效。

          </div>
        
        </div> 
        <div class="pc-btn">
          <div class="button" @click="scrollFun">
            了解更多 
            <img src="../assets/image/arrow.png" alt="">
          </div>
        </div>
        <div class="app-btn">
          <div class="button" @click="scrollFun1">
            了解更多 
            <img src="../assets/image/arrow.png" alt="">
        </div>
        </div>
        
        <div class="guide" style="bottom: -.6rem;">
          <img src="../assets/img/right-line.png" alt="">
        </div>
      </div>
      <div class="bot-bx" >
        <div class="njdd" ref="targetElement">
          <div class="njdd-l">
            <div class="left">
              <div class="t">
                农机调度
              </div>
              <div class="cont">
                通过智能监控和数据分析，实现对农业机械的合理调配和优化使用，旨在提高农业生产效率，降低成本，从而助力农业现代化进程。
              </div>
              <router-link :to="{name: 'machinery'}" >
                <div class="button btn1">
                  了解更多 
                  <img src="../assets/image/arrow1.png" alt="">
                </div>
              </router-link>
              
            </div>
            <div class="right">
              <span></span>
              <div></div>
            </div>
          
          </div>
          <div class="njdd-r">
            <img class="img1" src="../assets/image/back.png" alt="">
          </div>
        </div>
        <div class="njdd" style="margin-top: 0;">
          <div class="njdd-r">
            <img class="img1" src="../assets/image/new-back.png" alt="">
           
          </div>
          <div class="njdd-l">
            <div class="right">
              <span></span>
              <div></div>
            </div>
            <div class="left" style="text-align: left;margin-left: 40px;">
              <div class="t">
                农忙互助
              </div>
              <div class="cont" style="width: 500px;">
                农村地区在农忙时节，农民之间通过自愿互利的原则，组织起来进行农业生产上的互助合作。
              </div>
              <router-link :to="{name: 'farming'}" >
                <div class="button btn1" style="float: left;">
                  了解更多 
                  <img src="../assets/image/arrow1.png" alt="">
                </div>
              </router-link>
            </div>
          
          
          </div>
          
        </div>
        <div class="njdd">
          <div class="njdd-l">
            <div class="left">
              <div class="t">
                农资甄选
              </div>
              <div class="cont">
                方便农业从业者或农户能够随时随地在线选购所需的农资产品，如农药、肥料、种子、农机具等。保证农资真实有效，降低农业生产成本。
              </div>
              <router-link :to="{name: 'agricultural'}">
                <div class="button btn1">
                  了解更多 
                  <img src="../assets/image/arrow1.png" alt="">
                </div>
              </router-link>
              
            </div>
            <div class="right">
              <span></span>
              <div></div>
            </div>
          
          </div>
          <div class="njdd-r" style="margin-left: 52px;display: flex;">
            <img class="img3" src="../assets/image/back3.png" alt="">
            <img class="img4" src="../assets/image/back4.png" alt="">
          </div>
        </div>
        <div class="njdd">
          <div class="njdd-l">
            <div class="left" >
              <div class="t">

              </div>
              <div class="cont" style="text-align: left;width: 300px">
                为农业从业者、投资者、政策制定者以及普通用户提供了丰富的、实时的、专业的农业信息，帮助他们做出更明智的决策。
              </div>
              <router-link :to="{name: 'news'}">
                <div class="button btn1">
                  了解更多 
                  <img src="../assets/image/arrow1.png" alt="">
                </div>
              </router-link>
              
            </div>
            <div class="right" style="margin-left: 260px;">
              <span></span>
              <div></div>
            </div>
          
          </div>
          <div class="njdd-r" style="margin-top: 250px;">
            <div class="t" style="padding-top: 0;">
              新闻资讯
            </div>
            <img class="img5" src="../assets/image/back5.png" alt="">
            
          </div>
        </div>
        <div class="zsk">
          <div class="zsk-t">
            <div class="zsk-l">
              知识库
            </div>
            <div class="right">
              <span></span>
              <div></div>
            </div>
          </div>
          <div class="zsk-b">
            <img src="../assets/image/zsk.png" alt="">
          </div>
          <router-link :to="{name: 'knowledge'}">
            <div class="button btn1" style="margin: 0 auto;clear: both;float: none;">
              了解更多 
              <img src="../assets/image/arrow1.png" alt="">
          </div>
          </router-link>
          
        </div>
      </div>
      <div class="app-bx" ref="targetElement1">
        <div class="content">
          <div class="cont-l">
            <div class="t">
              农机调度
            </div>
            <div class="app-imgbx">
              <div class="img1">
                <img src="../assets/image/back.png" alt="">
              </div>
            </div> 
            <div class="cont">
              通过智能监控和数据分析，实现对农业机械的合理调配和优化使用，旨在提高农业生产效率，降低成本，从而助力农业现代化进程。
            </div>
            <router-link :to="{name: 'machinery'}" >
              <div class="btn-bx">
                了解更多 <img src="../assets/image/arrow1.png" alt="">
              </div>
            </router-link>
            
          </div>
          <div class="cont-r">
            <span></span>
            <div></div>
          </div>
          <div class="guide guide1">
            <img src="../assets/img/left-line.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="cont-r">
            <span></span>
            <div></div>
          </div>
          <div class="cont-l" style="margin-right: 0;margin-left: .48rem;">
            <div class="t" style="text-align: left;">
              农忙互助
            </div>
            <div class="app-imgbx">
              <div class="img2">
                <img src="../assets/image/back1.png" alt="">
              </div>
              <div class="img2">
                <img src="../assets/image/back2.png" alt="">
              </div>
            </div> 
            <div class="cont">
              农村地区在农忙时节，农民之间通过自愿互利的原则，组织起来进行农业生产上的互助合作。
            </div>
            <router-link :to="{name: 'farming'}" >
              <div class="btn-bx" style="float: left;">
              了解更多 <img src="../assets/image/arrow1.png" alt="">
            </div>
            </router-link>
            
          </div>
          <div class="guide guide2">
            <img src="../assets/img/right-line.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="cont-l">
            <div class="t">
              农资甄选
            </div>
            <div class="app-imgbx" style="display: flex;">
              <div class="img3">
                <img src="../assets/image/back.png" alt="">
              </div>
              <div class="img4">
                <img src="../assets/image/back3.png" alt="">
              </div>
            </div> 
            <div class="cont">
              方便农业从业者或农户能够随时随地在线选购所需的农资产品，如农药、肥料、种子、农机具等。保证农资真实有效，降低农业生产成本。
            </div>
            <router-link :to="{name: 'agricultural'}">
              <div class="btn-bx">
                了解更多 <img src="../assets/image/arrow1.png" alt="">
              </div>
            </router-link>
            
          </div>
          <div class="cont-r">
            <span></span>
            <div></div>
          </div>
          <div class="guide guide1">
            <img src="../assets/img/left-line.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="cont-r">
            <span></span>
            <div></div>
          </div>
          <div class="cont-l" style="margin-right: 0;margin-left: .48rem;">
            <div class="t" style="text-align: left;">
              新闻资讯
            </div>
            <div class="app-imgbx">
              <div class="img1">
                <img src="../assets/image/back.png" alt="">
              </div>
              
            </div> 
            <div class="cont">
              为农业从业者、投资者、政策制定者以及普通用户提供了丰富的、实时的、专业的农业信息，帮助他们做出更明智的决策。
            </div>
            <router-link :to="{name: 'news'}">
              <div class="btn-bx" style="float: left;">
                了解更多 <img src="../assets/image/arrow1.png" alt="">
              </div>
            </router-link>
            
          </div>
          <div class="guide guide2">
            <img src="../assets/img/right-line.png" alt="">
          </div>
        </div>
        <div class="content1">
          <div class="cont-t" style="margin-top: .7rem;">
            <div class="cont-l">
              <div class="t">
                知识库
              </div>
            </div>
            <div class="cont-r" style="margin-top: .1rem;margin-right: .1rem;">
              <span></span>
              <div></div>
            </div>
          </div>
        
          <div class="" style="margin-top: -3rem;">
            <img class="img5" src="../assets/img/zsk.png" alt="">
          </div>
          <router-link :to="{name: 'knowledge'}">
            <div class="btn-bx">
              了解更多 <img src="../assets/image/arrow1.png" alt="">
            </div>
          </router-link>
          
        </div>
      </div>
    </div>
   
    <router-view></router-view>
  </div>
</template>
<script>
// @ is an alias to /src
import { swiper, swiperSlide } from 'vue-awesome-swiper';
 
// Import Swiper styles
import 'swiper/css/swiper.css';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOptions: {
         
        pagination: ".swiper-pagination",
        initialSlide: 0,//默认第几张
        loop:true, //循环
        autoplayDisableOnInteraction:false,//触摸后再次自动轮播
        autoplay:5000, //每张播放时长3秒，自动播放
        speed:1000,//滑动速度
        deep: false,
          //左右点击
          // navigation: {
          // nextEl: '.swiper-button-next',
          // prevEl: '.swiper-button-prev',
        // },
      },
      images: [
        {
          img: require('../assets/image/banner3.png'),
          img1: require('../assets/img/bg1.png'),
          title:'愿景',
          cont:'让饮食从种植源头一直到餐桌可数据化，数字化促进乡村振兴'
        },
        {
          img: require('../assets/image/banner1.png'),
          img1: require('../assets/img/bg2.png'),
          title:'使命',
          cont:'保障种植安全、粮食安全、饮食安全'
        },
        {
          img: require('../assets/image/banner2.png'),
          img1: require('../assets/img/bg3.png'),
          title: '价值观',
          cont:'真实·积累·简单·方便'
        },
        // ...
      ],
    }
  },
  computed: {
      swiper() {
      return this.$refs.mySwiper.swiper
      },
      swiper1() {
        return this.$refs.mySwiper1.swiper
      }
  },
  methods: {
    scrollFun() {
      this.$refs.targetElement.scrollIntoView({
        behavior: 'smooth'
      });
    },
    scrollFun1() {
      this.$refs.targetElement1.scrollIntoView({
        behavior: 'smooth'
      });
    },
    prev1() {
      this.swiper1.slidePrev();
    },
    next1() {
          this.swiper1.slideNext()
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
          this.swiper.slideNext()
    },
    handleScroll() {
      // 获取指定组件的DOM元素  
      const target = document.getElementById(this.targetId);
      if (target) {
        // 获取指定组件的位置和大小信息  
        const targetRect = target.getBoundingClientRect();
        // 检查指定组件是否已经在视口中可见  
        const isScrolledIntoView = targetRect.top <= window.innerHeight && targetRect.bottom >= 0;
        if (isScrolledIntoView) {
          // 如果指定组件已经在视口中可见，将isScrolled属性设置为true  
          this.isScrolled = true;
          // 在控制台中打印一条消息，表示已经滚动到指定组件  
          console.log('已经滚动到指定组件');
        } else {
          // 如果指定组件不在视口中可见，将isScrolled属性设置为false  
          this.isScrolled = false;
          // 在控制台中打印另一条消息，表示没有滚动到指定组件  
          console.log('没有滚动到指定组件');
        }
      }
    },
   
  },
  
}
</script>
<style lang="less" scoped>
.homeView{
  overflow: hidden;
}

// 移动端
@media screen and (max-width: 980px) {
  .pc-swiper,.bot-bx,.pc-btn{
    display: none;
  }
  .pc-btn{
    display: none;
  }
  .banner-bx0{
    width: 7.5rem;
    height: 15.56rem;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
    .wht{
      width: 4.6rem;
      height: 5.84rem;
      
      margin-top: 5rem;
    }
    .spans{
      left: 2.31rem;
      top: 7.2rem;
      position: absolute;
      font-family: DingTalk JinBuTi;
      font-size: .96rem;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0px;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #FFFFFF;
      font-style: italic;

    }
    .bot{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2.8rem;
      background:  rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      padding: .56rem .6rem;
      color: #fff;
      div{
        text-align: left;
        &:nth-child(1){
          font-size: .28rem;
        }
        &:nth-child(2){
          width: 4.6rem;
          font-size: .32rem;
          padding-top: .24rem;
        }
      }
    }
    
  }
  .box{
    height: 15.56rem;
    background-image: url('../assets/img/bg.png');
    background-size: 100% 100%;
    text-align: center;
    color: #333333;
    box-sizing: border-box;
    padding: 0 .6rem;
    position: relative;
    .guide{
      position: absolute;
      right: .6rem;
      img{
        width: 1.7rem;
        height: 2.8rem;
      }
    }
    .t{
      font-size: .48rem;
      font-weight: 600;
      padding-top: 3.2rem;
      padding-bottom: .96rem;
    }
    .button{
      margin: 2.28rem auto;
      width: 2rem;
      height: .72rem;
      border-radius: .62rem;
      opacity: 1;
      font-size: .24rem;
      box-sizing: border-box;;

      /* 自动布局 */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: .12rem .32rem;
      gap: .08rem;

      background: #008C11;
      color: #fff;
      img{
        width: .32rem;
        height: .32rem;
      }
    }
  .font{
      font-size: .32rem
    }
  }
  .app-bx{
    box-sizing: border-box;
    padding: 0 .6rem;
    color: #333333;
    .btn-bx{
      width: 2rem;
      height: .72rem;
      border-radius: .62rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: .12rem .3rem;
      gap: .08rem;

      background: #FFFFFF;

      box-sizing: border-box;
      border: .01rem solid #008C11;
      color: #009913;
      font-size: .24rem;
      float: right;
      img{
        width: .32rem;
        height: .32rem;
      }
    }
    .cont-r{
        margin-top: .9rem;
        span{
          display: inline-block;
          width: .16rem;
          height: .16rem;
          background: #008C11;
          border-radius: 50%;
        }
        div{
          height: 4.36rem;
          width: .01rem;
          background: #CCCCCC;
          margin-left: .07rem;
          margin-top: -.2rem;
        }
      }
    .content1{
      color: #333333;
      padding-bottom: .8rem;
      .cont-t{
        display: flex;
        .cont-l{
          flex: 1;
          .t{
            text-align: center;
            font-size: .48rem;
            font-weight: 600;
          }
        }
      }
      .img5{
        text-align: center;
        width: 6.5rem;
        height: 6rem;
        margin-top: -.5rem;
      }
      .btn-bx{
        margin: .4rem auto;
        float: none;

      }
    }
    .content{
      position: relative;
      display: flex;
      padding-bottom: 1.04rem;
      .guide1{
        position: absolute;
        bottom: -.8rem;
        img{
          width: 1.4rem;
          height: 2.2rem;
        }
      }
      .guide2{
        position: absolute;
        bottom: -.8rem;
        right: 0;
        img{
          width: 1.4rem;
          height: 2.2rem;
        }
      }
      .cont-l{
        flex: 1;
        margin-right: .48rem;
        .t{
          font-size: .48rem;
          font-weight: 600;
          text-align: right;
          padding: .8rem 0 .56rem 0;
        }
        .app-imgbx{
          .img1{
            img{
              width: 5.54rem;
              height: 3.6rem;
            }
          }
          .img2{
            img{
              width: 5.34rem;
              height: 1.72rem;

            }
          }
          .img3{
            margin-right: .14rem;
            img{
              width: 2.8rem;
              height: 2.56rem;
            }
            
          }
          .img4{
            img{
              width: 2.8rem;
              height: 3.6rem;
            }
            
          }
          
        }
        .cont{
          font-size: .28rem;
          padding: .56rem 0;
        }
        
      }
     
     
    } 
  }
  .swiper-button-prev,.swiper-button-next{
      top: 92%;
    
      width: 34px!important;
      height: 34px!important;
      background: #fff;
      border-radius: 50%;
    }
    .swiper-button-prev{
      left: 77%;
    }
    .swiper-button-next{
      right: 3%;
    }
    .swiper-button-prev:after{
      font-size: .18rem;
      color: #333333;
    }
    .swiper-button-next:after{
      font-size: .18rem;
      color: #333333;
    }
    .font-bx{
      display: none;
    }
    .app-swiper{
      .banner-bx{
        width: 100%;
        height: 12.4rem;
        background-size: 100% 100%;
        text-align: center;
        position: relative;
      
        .wht{
          width: 4.5rem;
          height: 5.4rem;
          margin-top: 3.6rem;
        }
        .spans{
          color: #fff;
          font-family: DingTalk JinBuTi;
          font-size: .96rem;
          position: absolute;
          top: 47%;
          left: 30%;
          font-style: italic;
        }
        .bot{
          
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          color: #fff;
          background: rgba(0, 0, 0, 0.2);
          height: 2.8rem;
          text-align: left;
          div{
            width: 4.6rem;
            margin-left: .6rem;
            &:nth-child(1){
              font-size: .28rem;
              padding-top: .56rem;
            }
            &:nth-child(2){
              margin-top: .24rem;
              font-size: .32rem;
            }
          }
        }
      }
    }
}

// pC端
@media screen and (min-width: 980px) {
  .pc-swiper{
    margin-top: 128px;
  }

  .app-swiper,.app-bx,.app-btn{
    display: none;
  }
  .font-bx1{
    display: none;
  }
  .banner-bx{
    position: relative;
    width: 100%;
    height: 952px
  }
  .images{
    width: 100%;
    height: 952px;
   
    img{
      height: 100%;
    }
  }
  .spans{
    position: absolute;
    top: -48px;
    left: 43%;
    text-align: center;
    z-index: 9999;
    color: #fff;
    font-size: 96px;
    margin-top: 400px;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    font-family: DingTalk JinBuTi;
    font-style: oblique;

  }
  .wht{
    position: absolute;
    left: 16%;
    top: 260px;
    z-index: 9999;
    width: 1298px;
    height: 304px;
  }
  .bot{
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    height: 160px;
    line-height: 160px;
    box-sizing: border-box;
    padding: 0 16% 0 16%;
    // text-align: center;
    span{
      color: #fff;
      &:nth-child(1){
        display: inline-block;
        width: 72px;
        font-size: 18px;
      }
      &:nth-child(2){
        font-size: 24px;
        padding-left: 72px;
        
      }
    }
  }
  .box{
    height: 1080px;
    background-image: url('../assets/image/bg.png');
    background-size: 100% 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 0 16%;
    .t{
      color: #333333;
      font-size: 48px;
      font-weight: 600;
      padding-top: 340px;
      padding-bottom: 32px;
    }
    .font{
      color: #333333;
      font-size: 32px;
      line-height: 64px;
    }
   
  }
  .button{
    margin: 68px auto;
    width: 200px;
    height: 72px;
    border-radius: 62px;
    opacity: 1;

    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 8px;
    background: #008C11;
    color: #FFFFFF;
    font-size: 24px;
    img{
      width: 32px;
      height: 32px;
    }
    
  }
  .btn1{
    margin: 0 auto;
    float: right;
    background: #fff;
    border: 1px solid #008C11;
    color: #009913;
    margin-top: 286px;
  }
.njdd{
  
    height: 940px;
    box-sizing: border-box;
    padding: 0 16%;
    display: flex;
    // background: #000;
    margin-top: 260px;
    .njdd-l{
      flex: 1;
      display: flex;
      .left{
        text-align: right;
        .t{
          color: #333333;
          font-size: 48px;
          font-weight: 600;
          padding-top: 240px;
        }
        .cont{
          width: 600px;
          color: #333333;
          font-size: 24px;
          padding-top: 56px;

        }
      }
      .right{
        margin-top: 268px;
        margin-left: 42px;
        span{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #008C11;
          border-radius: 50%;
        }
        div{
          height: 413px;
          width: 1px;
          background: #CCCCCC;
          margin-left: 7px;
          margin-top: -7px;
        }
      }
    }
    .njdd-r{
      .t{
          color: #333333;
          font-size: 48px;
          font-weight: 600;
          padding-top: 240px;
        }
      flex: 1;
      margin-top: 378px;
      margin-left: 52px;
      .img1{
        width: 600px;
        height: 472px;
      }
      .img2{
        width: 600px;
        height: 226px;
        margin-bottom: 10px;
      }
      .img3{
        margin-top: 136px;
        width: 262px;
        height: 336px;
        margin-right: 50px;
      }
      .img4{
        width: 262px;
        height: 472px;
      }
      .img5{
        width: 950px;
        height: 472px;
        margin-left: -300px;
        margin-top: 56px;
      }
    }
    .bot-bx{
      margin: 0 auto;
      width: 94%;
      // margin: 0 auto;
      height: 5884px;
      background-image: url('../assets/image/bg1.png');
      background-size: 100% 100%;
      margin-top: -260px;
      border: 1px solid rgba(0, 0, 0, 0.001);
      
    }
   
  }
  .swiper-button-prev,.swiper-button-next{
      top: 92%;
    
      width: 56px;
      height: 56px;
      background: #fff;
      border-radius: 50%;
    }
    .swiper-button-prev{
      left: 77%;
    }
    .swiper-button-next{
      right: 16%;
    }
    .swiper-button-prev:after{
      font-size: 16px;
      color: #333333;
    }
    .swiper-button-next:after{
      font-size: 16px;
      color: #333333;
    }
    .zsk{
      height: 1080px;
      .zsk-t{
        display: flex;
        .zsk-l{
          flex: 1;
          text-align: right;
          color: #333333;
          font-size: 48px;
          font-weight: 600;
          padding-top: 240px;
        }
        .right{
          flex: 1;
          margin-top: 268px;
          margin-left: 42px;
          span{
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #008C11;
            border-radius: 50%;
          }
          div{
            height: 413px;
            width: 1px;
            background: #CCCCCC;
            margin-left: 7px;
            margin-top: -7px;
          }
        }
      }
      .zsk-b{
        text-align: center;
        margin-top: -350px;
      }
    }
    .bot-bx{
        margin: 0 auto;
        width: 94%;
    // margin: 0 auto;
      height: 5884px;
      background-image: url('../assets/image/bg1.png');
      background-size: 100% 100%;
      margin-top: -260px;
      border: 1px solid rgba(0, 0, 0, 0.001);
      
    }
    .guide{
      display: none;
    }
}

  @media screen and (min-width: 980px) and (max-width: 1200px) {
    
    .swiper-button-next{
      right: 3%;
    }
    .swiper-button-prev{
    left: 85%;
    }
   
}

@media screen and (min-width: 1200px) {

}

</style>