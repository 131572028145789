const createVirtualPort = () => {
  const minPort = 1000;
  const maxPort = 65535;
  const port = Math.floor(Math.random() * (maxPort - minPort + 1)) + minPort;
  return port;
}

export const getPublicIp = async () => {
  try {
    const response = await fetch('https://ipinfo.io/ip');
    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

export const getPublicIpAddress = async () => {
  const ip = await getPublicIp();
  const port = createVirtualPort();
  return `${ip}:${port}`;
}