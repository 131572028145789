import request from '@/utils/request'

export const userlistens = data => {
  return request({
    method: 'post',
    url: 'tourist/whtwebTouristBehavior/insetTouristBehavior',
    data
  })
}


export const visitTheOfficialWebsite = data => {
  return request({
    method: 'post',
    url: '/whtWeb/whtwebAccessDetails/add',
    data
  })
}