import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import api from './api/axios';
import Vant from 'vant';
import 'vant/lib/index.css';
import '../src/assets/css/global.css'
import '../src/assets/js/public'
import VueAwesomeSwiper from 'vue-awesome-swiper';
 
Vue.use(VueAwesomeSwiper);
Vue.prototype.$api = api;
Vue.use(api).use(Vant)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
