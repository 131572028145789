import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerContent: sessionStorage.getItem('select') || '/',
    isQuestionnairesView: false,
    currentPalyVideo: null,
    count: 1
  },
  getters: {
  },
  mutations: {
    setHeaderContent(state, content) {
      state.headerContent = content; // 更新页头的内容
    },
    setQuestionnairesView(state, isQuestionnairesView) {
      state.isQuestionnairesView = isQuestionnairesView;
    },
    setIsPalyVideo(state, currentPalyVideo) {
      state.currentPalyVideo = currentPalyVideo
    }
  },
  actions: {
    updateHeaderContent({ commit }, content) {
      // sessionStorage.setItem('select', route); // 将路由存储到sessionStorage
      commit('setHeaderContent', content); // 调用mutation更新页头的内容
    },
  },
  modules: {
  }
})
