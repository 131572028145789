export const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' }
export const baseUrl = 'https://五画田.com:8080/jeecg-boot/'  //生产接口
// export const baseUrl = 'http://192.168.110.219:8080/jeecg-boot/'    //测试接口
export const timeout = 6000
export const transformResponse = [function (data) { try { return JSON.parse(data) } catch (err) { return data } }]
export const requst = c => { console.log(c) }
export const response = c => { console.log(c) }
export default {
  headers,
  baseUrl,
  timeout,
  transformResponse,
  requst,
  response
}