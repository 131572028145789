<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header" id="header">
    <div style="display: flex;justify-content: space-between;position: relative;">
      <div class="logo-bx">
        <img class="logo" src="../assets/image/logo.png" alt="">
        <img class="logo1" src="../assets/img/logo.png" alt="">
      </div>
      <div class="more" @click="openFun">
        <img class="more-fun" src="../assets/img/more.png" alt="">
      </div>
      <div class="more-bx" v-show="isMore">
        <div v-for="(item,index) in tabArr" :key="index" @click="switchTab(item,index)" 
        :class="$store.state.headerContent == item.path? 'active1':''">
          {{ item.name }}
        </div>
      </div>
    </div>
    
    
    <div class="tab-box">

      <div class="tab-item" v-for="(item,index) in tabArr" :key="index" @click="switchTab(item,index)" 
        :class="$store.state.headerContent == item.path? 'active':''">
        {{ item.name }}
      </div>
    </div>
   
  </div>
</template>
<script>
export default {
    
  data() {
    return {
      isMore: false,
      select: '/',
      tabArr: [
        {
          name: '首 页',
          path: '/'
        },
        {
          name: '公司概况',
          path: '/company'
        },
        {
          name: '产品中心',
          path: '/product'
        },
        {
          name: '新闻资讯',
          path: '/info'
        },
        {
          name: '联系我们',
          path: '/about'
        },
        
      ],
      active: 0,
      show: false
    }
  },
  mounted() {
    this.$store.state.headerContent =  sessionStorage.getItem('select') || '/'
    this.ListenerScroll()
  },
 
  methods: {
    openFun() {
      this.isMore = !this.isMore
    },
    closeFun() {
      this.isMore = false
    },
    switchTab(item,index) {
      window.scrollTo(0, 0)
      sessionStorage.setItem('select', item.path)
      this.$store.state.headerContent = sessionStorage.getItem('select') || '/'
      this.show = false
      this.active = index;
      if (this.$route.path !== item.path) this.$router.push(item.path)
      this.closeFun()
    },
    changeSet() {
      this.show = true
    },
    ListenerScroll() {
      window.addEventListener('scroll', function () {
        // // 获取当前滚动位置
        const scrollTop = window.scrollY;
        // 执行相应操作（根据滚动位置改变导航栏样式等）
        if (scrollTop >= 80) {
          document.getElementById('header').className = 'header head-active'
        } else {
          document.getElementById('header').className = 'header'
        }
      });
      document.addEventListener('click',(e)=>{
     
        if(e.target.className!='more-fun'){
            this.isMore = false;
        }
      })
    },

  },
  // watch(){
    
  // }
}
</script>
<style lang="less" scoped>
.header {
  z-index: 99999;
}





// 移动端
@media screen and (max-width: 980px) {
  .header {
    width: 100%;
    height: .96rem;
    line-height: .96rem;
    background: #fff;
    opacity: 1;
    z-index: 99;
    position: fixed;
    top: 0;
    
  }
  
  .logo-bx{
    width: 2.45rem;
    height: .6rem;
    margin: .18rem 0 0 .2rem;
    .logo{
      display: none;
    }
    // img{
    //   width: 100%;
    //   height: 100%;
    // }
    .logo1{
      width: 2.01rem;
      height: .62rem;
    }
  }
  .more{
   
    margin-right: .2rem;
    margin-top: .2rem;
    img{
      width: .58rem;
      height: .58rem;
    }
  }
  .more-bx{
    position: absolute;
    width: 3.2rem;
    height: 4.6rem;
    background: #fff;
   
    right: 0;
    top: .9rem;
    text-align: center;
    box-sizing: border-box;
    padding: .3rem 0;
    div{
      color: #333333;
      font-size: .3rem;
      line-height: .8rem;
    }
  }
  .tab-box{
    display: none;
  }
}

// pC端
@media screen and (min-width: 980px) {
  .more,.more-bx{
    display: none;
  }
  .header {
    width: 100%;
    height: 128px;
    vertical-align: middle;
    background: #fff;
    // opacity: 1;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16%;
    box-sizing: border-box;
    .logo1{
      display: none;
    }
    .logo{
      width: 200px;
      height: 60px;
      vertical-align: middle;
      // margin-left: 310px;
    }
    }
    .tab-box{
      display: flex;
      // margin-right: 310px;
      .tab-item{
        color: #333333;
        font-size: 18px;
        margin-right: 57px;
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
        &:nth-child(1){
          letter-spacing: 9.5px;
          width: 76px;
          text-align: center;
        }
      }

  }
}
.active1{
  color: #008C11!important;
}
.active{
  position: relative;
  color: #008C11!important;
}
.active::after{
  content: '';
  display: block;
  width: 90%;
  height: 4px;
  background: #F8C40C;
  position: absolute;
  left: 2px;
  bottom: -8px;
  border-radius: 26px;
}
@media screen and (min-width: 980px) and (max-width: 1200px) {
  .header{
    padding: 0 8%;
  }
  .tab-item{
    margin-right: 50px!important;
  }

}

@media screen and (min-width: 1200px) {
  
}
</style>