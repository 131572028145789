<template>
  <div id="app" @click="userBehaviorInterception($event)">
  
    <header-compont v-show="!$route.meta.showNav || !isMobile()" 
    />
    <router-view />
    <foot-compont />
  </div>
</template>
<script>
import headerCompont from './components/header'
import footCompont from './components/footerCommon'
import { userlistensAPI, visitTheOfficialWebsiteAPI } from '@/api'
import { mapState } from 'vuex'
import { getPublicIpAddress } from '@/utils/getPublicIpAddress'
import { generateVirtualId } from '@/utils/createVirtualId'
// import axios from 'axios'
export default {
  components: {
    headerCompont,
    footCompont
  },
  name: 'app',
  methods: {
    isMobile() {
      return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    },
    userBehaviorInterception(event) {
      if (event.target.hasAttribute('data-is-listening')) {
        const now = new Date();
        let currentTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
        let userListening = JSON.parse(localStorage.getItem('userListening'))
        userListening.userClick.push({
          createTime: currentTime,
          browseTime: '',
          moduleId: event.target.getAttribute('data-is-listening')
        })
        localStorage.setItem('userListening', JSON.stringify(userListening))
      }
    },
    userBehaviorData() {
      if (localStorage.getItem('userListening')) {return}
      let userListening = new Object()
      userListening.touristId = localStorage.getItem('touristId')
      userListening.publicIp=localStorage.getItem('ipAddress')
      userListening.devices = navigator.platform
      userListening.userClick = []
      localStorage.setItem('userListening', JSON.stringify(userListening))
    },
    async updataUserBehavior() {
      console.log(localStorage.getItem('userListening'))
      // let userListening= JSON.parse(localStorage.getItem('userListening'))
      if (JSON.parse(localStorage.getItem('userListening')).userClick.length === 0) {
        return
      }
      const response = await userlistensAPI(localStorage.getItem('userListening'))
      this.userBehaviorData()
      console.log(response)
    },
    async init() {
      this.storageIP()
      this.storageTouristId()
      this.userBehaviorData()
      this.updataUserBehavior()
      this.visitTheOfficialWebsite()
    },
    storageTouristId() {
      localStorage.getItem('touristId') || localStorage.setItem('touristId', generateVirtualId('gw-xx-yyyy'))
    },
    async storageIP() {
      localStorage.getItem('ipAddress') || localStorage.setItem('ipAddress', await getPublicIpAddress())
    },
    async visitTheOfficialWebsite() {
      let ipObject = new Object()
      ipObject.ip = localStorage.getItem('ipAddress')
      ipObject.touristId = localStorage.getItem('touristId')
      while (ipObject) {visitTheOfficialWebsiteAPI(ipObject); ipObject = false}
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  created() {
    
  },
  mounted() {
    window.addEventListener('beforeunload', this.scrollToTop);
  },
  beforeDestroy() {
  window.removeEventListener('beforeunload', this.scrollToTop);
},
  computed: {
    ...mapState(['isQuestionnairesView'])
  },
  watch: {
    // isQuestionnairesView(newVal) {
    //   if (newVal) {
    //     console.log(document.getElementById('titlll').innerText="")
    //   }
    // }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
}
</style>
